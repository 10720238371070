@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #2b262d;
  --secondary-color: #8024c4;
  --black-color: #f2ebe5;
  --lightGray-color: #647295;
  --gray-color: #8024c4;
  --brown-color: #8024c4;
  --white-color: #2b262d;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--white-color);
}